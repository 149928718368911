import {Component, OnInit} from '@angular/core';
import {ConsultantTalentProfilePictureResource, ConsultantTalentResource} from '../../generated/resources';
import {ConsultantTalentData, ConsultantTalentFilterData} from '../../generated/data';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../utility/side-sheet/dialog.service';
import {TalentProfileDialogComponent} from '../dialog-sheets/talent-profile-dialog/talent-profile-dialog.component';
import {environment} from '../../environments/environment';
import {FormBuilder, FormControl} from '@angular/forms';
import {InviteTalentDialogComponent} from '../dialog-sheets/invite-talent-dialog/invite-talent-dialog.component';
import {
    RateTalentContactedDialogComponent
} from "../dialog-sheets/rate-talent-contacted-dialog/rate-talent-contacted-dialog.component";
import {ConsultantAuthService} from '../service/consultant.auth.service';
import {MediaService} from "../service/media.service";

@Component({
    selector: 'app-talents',
    templateUrl: './talents.component.html',
    styleUrls: ['./talents.component.scss']
})
export class TalentsComponent implements OnInit {

    public talentsData: ConsultantTalentData[] = [];
    public funnelStateFilterControl: FormControl = new FormControl(null);
    public acquiredStartControl: FormControl = new FormControl(null);
    public acquiredEndControl: FormControl = new FormControl(null);
    public markedAsLostControl: FormControl = new FormControl(false);
    public talentStateControl: FormControl = new FormControl(null);
    public talentRatedControl: FormControl = new FormControl(null);
    public displayedColumns: string[] = ['picture', 'lastName', 'firstName', 'assignDate', 'phoneNumber', 'funnel', 'firstContact', 'recommendPosition', 'coachingServices', 'actions'];
    public pageSizeOptions = [5, 8, 12, 20, 50];
    public totalElements = 0;
    public page = 0;
    public pageSize = 8;
    public totalPages;
    public loading: boolean = true;

    constructor(
        private fb: FormBuilder,
        private talentResource: ConsultantTalentResource,
        private consultantTalentProfilePictureResource: ConsultantTalentProfilePictureResource,
        private dialogService: DialogService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: ConsultantAuthService,
        public mediaService: MediaService
    ) {
    }

    ngOnInit(): void {
        this.openTalentProfileIfNeccessary()
        this.loadTalents();
        this.getDisplayedColumns()
    }

    getDisplayedColumns(): void {
        if (this.mediaService.getMaxWidthQuery(1050).matches) {
            this.displayedColumns.splice(2, 1)
        }
    }

    openTalentProfileIfNeccessary() {
        let talentId = parseInt(this.route.snapshot.queryParamMap.get('talentId'));
        if (talentId) {
            this.viewTalentProfile(talentId)
        }
    }

    loadTalents(): void {
        this.loading = true;
        this.talentsData = [];

        let filterData: ConsultantTalentFilterData = {
            createDateEnd: this.talentResource.convertDatesDeep(this.acquiredEndControl.value?.toDate()),
            createDateStart: this.talentResource.convertDatesDeep(this.acquiredStartControl.value?.toDate()),
            activeTalents: this.talentStateControl.value,
            markedAsLost: this.markedAsLostControl.value,
            funnelstate: this.funnelStateFilterControl.value,
            talentRated: this.talentRatedControl.value

        }
        this.talentResource.getTalentsOfConsultant(
            filterData,
        {page: this.page,
            pageSize: this.pageSize}
        ).then(talents => {
            this.talentsData = talents.content
            this.totalElements = talents.totalElements
            this.totalPages = talents.totalPages;
            this.getTalentImageUrls();
            this.loading = false;
        })
    }

    handlePage(event): void {
        this.page = event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadTalents();
    }

    resetDate() {
        this.acquiredEndControl.setValue(null);
        this.acquiredStartControl.setValue(null);
    }

    openInviteTalentDialog() {
        const comp = this.dialogService.openOverlay(InviteTalentDialogComponent, '600px');
        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(() => {
            subscription.unsubscribe();
        });
    }

    openRateTalentContactedDialog(talentId: number) {
        const comp = this.dialogService.openOverlay(RateTalentContactedDialogComponent, '600px');
        comp.instance.talentId = talentId
        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
            if (result) this.loadTalents()
            subscription.unsubscribe();
        });
    }

    viewTalentProfile(talentId: number) {
        const comp = this.dialogService.openOverlay(TalentProfileDialogComponent, '1200px');
        comp.instance.talentId = talentId;

        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(() => {

            subscription.unsubscribe();
        });
    }

    getTalentImageUrls() {

        this.talentsData.forEach(data => {
            if (data.profilePictureId) {
                this.getTalentProfilePictureHash(data).then(imageHash => {
                    data['imgUrl'] = 'url(' + environment.apiUrl + `/consultant/talents/${data.id}/profilePicture/${imageHash})`
                })
            }
            else data['imgUrl'] = 'url(../assets/images/blurred_user.png)'
        })
    }

    getTalentProfilePictureHash(talent: ConsultantTalentData): Promise<string> {
        return this.consultantTalentProfilePictureResource.getProfileImageHash(talent.id)
    }

}
